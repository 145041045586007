.parentContainer {
    background: linear-gradient(180deg, rgba(241, 73, 41, 1) 0%, rgba(211, 12, 73, 1) 42%, rgba(85, 11, 96, 1) 86%, rgba(34, 1, 50, 1) 100%),
    url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/pattern_top_games.webp");
    background-blend-mode: color;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 4.6vw;
}

.mainContainer {
    padding: 18px 0px;
    font-family: var(--font-primary);
    color: white;
}

.tagline {
    display: none;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: center;
    background-clip: text;
    line-height: normal;
    color: #fff;
    margin: 0;
}

.heading {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.heading > h2 {
    max-width: 65vw;
    text-align: center;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2.25px;
    font-size: clamp(16px, 3.12vw, 60px);
    text-transform: uppercase;
    color: white;
}

.sliderParent {
    padding: 20px 0px;
}

.slideItem {
    position: relative;
}

#popularGamesAnimLink {
    cursor: pointer;
    padding-top: 10px;
}

.slideImage {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    bottom: 20px;
    width: 80%;
    height: clamp(160px, 16.6vw, 320px);
    transition: all 0.5s ease;
    border-radius: 2vw;
    overflow: hidden;
    margin: auto;
    -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0.1)));
    cursor: pointer;
    padding-top: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mobileInnerSliderContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mobileInnerSliderContainer {
    display: none;
    padding: 8px;
    overflow: scroll;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.mobImgContainer {
    margin: 0 5px;
}

.mobGameName {
    margin-top: 8px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.56;
    text-align: center;
    text-transform: uppercase;
}

.gameContent {
    margin: clamp(20px, 2.6vw, 50px) 0px 20px;
    text-align: center;
    line-height: normal;
}

.gameContentHeading {
    font-size: clamp(18px, 2.9vw, 56px);
    font-weight: 900;
    letter-spacing: 2px;
    cursor: pointer;
}

.gameContentDesc {
    font-size: clamp(12px, 1.6vw, 32px);
    padding: 0px clamp(50px, 7.8vw, 150px);
}

.gameContentDesc p {
    min-height: clamp(38px, 5.3vw, 102px);
}

@media screen and (max-width: 767px) {
    .mainContainer {
        padding: 5px 0px;
    }

    .mobImgContainer {
        position: relative;
        width: 107px;
        height: 83px;
        overflow: hidden;
        border-radius: 10px;
    }

    .sliderParent {
        padding: 0px 0px;
    }

    .mobileInnerSliderContainer {
        display: flex;
    }

    .heading {
        padding-bottom: clamp(10px, 2vw, 40px);
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to right, rgba(255, 255, 255, .2) 3%, #f6c401 57%, rgba(255, 255, 255, .1) 99%);
        margin-bottom: 20px;
    }

    .tagline {
        display: block;
    }
}

@media screen and (max-width: 576px) {
    .gameContent {
        display: none;
    }
}